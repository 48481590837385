









































































import Vue from 'vue';
import Component from 'vue-class-component';
import { UserInterface } from '../store/interface/User';
import CommonIcon from './CommonIcon.vue';
import { SECUTIX_URL } from '../store/Api';

const LmsLogo = () => import('../assets/logo-horizontal.svg');

@Component({
  components: {
    CommonIcon,
    LmsLogo,
  },
})
export default class CommonLogin extends Vue {
  $refs!: {
    loginForm: HTMLFormElement;
  }

  currentUser: UserInterface | null = null;
  error = null;
  publicInfo = null;
  rememberMe = false;
  username = null;
  password = null;
  requestB64 = null;
  token = null;
  SSO: string|null = null;

  login (): void {
    const { loginForm } = this.$refs;
    if (loginForm.checkValidity()) {
      loginForm.submit();
    } else {
      loginForm.reportValidity();
    }
  }

  logout (): void {
    this.$root.$store.dispatch('logOutUser');
  }

  fetchToken (): void {
    this.$root.$store.dispatch('getPublicData', '/saml/request-token')
      .then((response) => {
        this.requestB64 = response.requestB64;
        this.token = response.token;
        this.SSO = response.provider;
      });
  }

  get loginUrl (): string {
    return `${SECUTIX_URL}/api/1/sso/saml/login`;
  }

  get relayState (): string {
    const { systemMode } = this.$root.$store.getters;
    return `${this.token},${systemMode},${document.documentElement.lang}`;
  }

  get forgotPasswordLink (): string {
    return `${SECUTIX_URL}/account/reset`;
  }

  mounted (): void {
    this.currentUser = this.$root.$store.getters.currentUser;
    this.fetchToken();
  }
}
